(function () {
	'use strict';
	angular
		.module('app')
		.factory('shares', [
			'$rootScope',
			'utilities',
			'dayback',
			'daybackIO',
			'manageSettings',
			'firebaseIO',
			'seedcodeCalendar',
			'manageUser',
			'manageSchedules',
			'manageFilters',
			'manageResources',
			'crypt',
			'manageMeasure',
			'manageEventSources',
			'calendarEvents',
			'dataStore',
			'$translate',
			'environment',
			shares,
		]);

	function shares(
		$rootScope,
		utilities,
		dayback,
		daybackIO,
		manageSettings,
		firebaseIO,
		seedcodeCalendar,
		manageUser,
		manageSchedules,
		manageFilters,
		manageResources,
		crypt,
		manageMeasure,
		manageEventSources,
		calendarEvents,
		dataStore,
		$translate,
		environment
	) {
		var shareAuthed;

		return {
			shareAuth: shareAuth,
			create: create,
			update: update,
			remove: remove,
			getUserShareList: getUserShareList,
			getShareURL: getShareURL,
			updateSettingsData: updateSettingsData,
			updateShareProperty: updateShareProperty,
			updateShareTimezone: updateShareTimezone,
			updateShareSEO: updateShareSEO,
			updateSwitchDate: updateSwitchDate,
			getBookmark: getBookmark,
			isPublic: isPublic,
		};

		function updateSettingsData(share, property, value, callback) {
			const settingsData = {};
			settingsData[property] = value;
			//Set settings data
			daybackIO.setShareData(
				share.id,
				'settings',
				settingsData,
				true,
				processResult,
				null,
				null
			);

			function processResult(result) {
				if (callback) {
					callback(result);
				}
			}
		}

		function updateShareProperty(share, property, value, callback) {
			var userOnly = isUserOnlyShare(
				property === 'shareWith' ? {shareWith: value} : share
			);
			var groupData = createGroupData(share);
			groupData[property] = value;

			daybackIO.setShareData(
				share.id,
				property,
				value,
				false,
				processResult,
				null,
				null,
				userOnly
			);
			function processResult(data) {
				if (property === 'shareWith') {
					if (!isPublic(value)) {
						//Get list of events associated with this share and delete them
						daybackIO.getShareData(
							share.id + '/events',
							function (events) {
								if (!events) {
									return;
								}
								var eventList = Object.keys(events);
								deleteSharedEvents(
									share.id,
									eventList,
									false,
									null
								);
							},
							null
						);
					}
					if (userOnly) {
						firebaseIO.setGroupData(
							'shares',
							share.id,
							null,
							false,
							null,
							null
						);
						firebaseIO.setUserData(
							null,
							'shares',
							share.id,
							groupData,
							true,
							callback
						);
					} else {
						firebaseIO.setUserData(
							null,
							'shares',
							share.id,
							null,
							false,
							null,
							null
						);
						firebaseIO.setGroupData(
							'shares',
							share.id,
							groupData,
							true,
							callback
						);
					}
				} else if (userOnly) {
					firebaseIO.setUserData(
						null,
						'shares/' + share.id,
						property,
						value,
						false,
						callback
					);
				} else {
					firebaseIO.setGroupData(
						'shares/' + share.id,
						property,
						value,
						false,
						callback
					);
				}
			}
		}

		function updateShareTimezone(
			share,
			showInTimezone,
			timezone,
			callback
		) {
			var shareID = share.id;
			var callbackSuccessCount = 0;
			var shareData = {
				showIntimezone: showInTimezone,
				timezone: timezone,
			};
			var settingsData = {
				showInTimezone: showInTimezone,
				clientTimezone: timezone,
			};
			var groupData = {
				showInTimezone: showInTimezone,
				timezone: timezone,
			};

			//Set share and group data
			daybackIO.setShareData(
				null,
				shareID,
				shareData,
				true,
				processResult,
				null,
				groupData,
				isUserOnlyShare(share)
			);

			//Set settings data
			daybackIO.setShareData(
				shareID,
				'settings',
				settingsData,
				true,
				processResult,
				null,
				null
			);

			function processResult(data) {
				callbackSuccessCount++;
				if (callbackSuccessCount === 2) callback(data);
			}
		}

		function updateShareSEO(share, property, value, callback) {
			if (!share) {
				return;
			}

			var shareID = share.id;

			var shareData = {};
			shareData[property] = value;

			//Set share and group data
			daybackIO.setShareData(
				null,
				shareID,
				shareData,
				true,
				processResult,
				null,
				shareData,
				false
			);

			function processResult(data) {
				if (callback) {
					callback(data);
				}
			}
		}

		function updateSwitchDate(share, switchDate, defaultDate, callback) {
			var shareID = share.id;
			var callbackSuccessCount = 0;

			var view = seedcodeCalendar.get('view');

			var start = moment(view.start);
			var end = moment(view.end).subtract(1, 'seconds');

			if (switchDate) {
				share.start = start.valueOf();
				share.end = end.valueOf();
				share.dateRange =
					moment(start).format('ll') +
					' - ' +
					moment(end).format('ll');
			}

			var shareData = {
				switchDate: switchDate,
				start: share.start,
				end: share.end,
			};
			var settingsData = {
				defaultDate: switchDate ? defaultDate : null,
			};
			var groupData = {
				switchDate: switchDate,
				start: share.start,
				end: share.end,
			};

			//Set share and group data
			daybackIO.setShareData(
				null,
				shareID,
				shareData,
				true,
				processResult,
				null,
				groupData,
				isUserOnlyShare(share)
			);

			//Set settings data
			daybackIO.setShareData(
				shareID,
				'settings',
				settingsData,
				true,
				processResult,
				null,
				null
			);

			function processResult(data) {
				callbackSuccessCount++;
				if (callbackSuccessCount === 2 && callback) {
					callback(data);
				}
			}
		}

		function shareAuth(callback) {
			var config = seedcodeCalendar.get('config');
			var platform = utilities.getDBKPlatform();
			var userToken = manageUser.setLinkedUserToken(
				config.linkedUserToken
			);
			var auth = manageUser.getAuth();
			auth.then(
				function (authData) {
					//Load the same callback for success and failure - We will look for no result to tell if it is a failure
					var user = daybackIO.getUser();
					if (
						user &&
						user.auth &&
						(platform !== 'dbkfm' ||
							(platform === 'dbkfm' && shareAuthed))
					) {
						callback(user);
					} else {
						daybackIO.loadUser(
							fetchUserCallback,
							fetchUserCallback
						);
					}
				},
				function (error) {
					callback(null);
				}
			);

			function fetchUserCallback(user) {
				if (!user || !user.group) {
					callback(null);
				}

				//If we have a valid user get the hash so we can verify a valid DBKO subscription or trial
				firebaseIO.getGroupData(
					'settings/activationHash',
					function (hash) {
						crypt.checkHashExpired(
							hash,
							'dbko',
							//If we have a valid hash run callback with user
							function () {
								//Recored that we have authorized this user and hash
								shareAuthed = true;
								callback(user);
							},
							//If we have expired run
							function (type) {
								callback(type);
							}
						);
					},
					null
				);
			}
		}

		function createGroupData(shareData) {
			if (!shareData) {
				return;
			}
			var groupData = {
				id: shareData.id,
				name: shareData.name,
				platform: shareData.platform,
				createdBy: shareData.createdBy,
				created: shareData.created,
				expires: shareData.expires,
				start: shareData.start,
				end: shareData.end,
				timezone:
					shareData.settings && shareData.settings.showInTimezone
						? shareData.settings.clientTimezone
						: null,
				shareWith: shareData.shareWith,
				switchDate: shareData.switchDate,
				favorite: shareData.favorite,
				seoTitle: shareData.seoTitle,
				seoDescription: shareData.seoDescription,
				seoImageURL: shareData.seoImageURL,
			};
			return groupData;
		}

		function create(
			name,
			expireDays,
			callback,
			progressCallback,
			extend,
			unit,
			showInTimezone,
			timezone,
			shareWith,
			switchDate,
			shareData
		) {
			var config = seedcodeCalendar.get('config');
			var schedules = seedcodeCalendar.get('schedules');
			var sidebar = seedcodeCalendar.get('sidebar');
			var view = seedcodeCalendar.get('view');
			var altView = seedcodeCalendar.get('alt-view');

			var start = moment(view.start);
			var end = moment(view.end).subtract(1, 'seconds');

			var publicShare = isPublic(shareWith || shareData.shareWith);

			var defaultDatePreference = publicShare
				? null
				: shareData.defaultDatePreference || null;

			var switchDateOutput = publicShare
				? switchDate || shareData.switchDate || null
				: null;

			var defaultDate;
			if (publicShare) {
				defaultDate = switchDateOutput ? config.defaultDate : null;
			} else if (defaultDatePreference === 'selectedDate') {
				defaultDate = config.defaultDate;
			} else {
				defaultDate = null;
			}

			if (extend) {
				var duration;
				if (view.name === 'month') {
					duration = extend === true ? 1 : extend;
					end = moment(view.intervalStart)
						.add(duration, 'month')
						.startOf('week')
						.add(41, 'days');
				} else {
					end = moment(end).add(extend, unit.toLowerCase());
				}
			}

			var user = daybackIO.getUser();
			var now = moment();
			var platform = utilities.getDBKPlatform();
			var changeSet = [];
			var eventSet = [];
			var eventList = {};
			var shareSchedules = {};
			var event;
			var schedule;
			//Create new share object
			var value = {
				type: 'share',
				id: shareData.urlSlug
					? utilities.stringToURLSlug(shareData.urlSlug)
					: shareData.id || utilities.generateUniquePublicID(),
				name: name || shareData.name || now.valueOf().toString(), //Convert time value to string
				sourceTypeID: 6,
				platform: platform,
				isPhone: environment.isPhone,
				userID: user.id,
				groupID: user.group.id,
				start: start.valueOf(),
				end: end.valueOf(),
				createdBy: shareData.createdBy || config.accountName,
				created: shareData.created || now.valueOf(),
				expires: now.add(expireDays || 90, 'days').valueOf(),
				timezone: showInTimezone ? timezone : null,
				showInTimezone:
					showInTimezone || shareData.showInTimezone || null,
				shareWith: shareWith || shareData.shareWith || null,
				switchDate: switchDateOutput,
				seoTitle: shareData.seoTitle || null,
				seoDescription: shareData.seoDescription || null,
				seoImageURL: shareData.seoImageURL || null,
				favorite: shareData.favorite || null,
				defaultDatePreference: defaultDatePreference,
				filters: {},
				allFilters: true,
				sidebar: {
					show: sidebar.show,
					view: sidebar.view,
				},
				settings: {
					defaultDate: defaultDate,
					defaultView: view.name,
					resourceColumns: config.resourceColumns,
					resourcePosition: config.resourcePosition,
					resourceDays: config.resourceDays,
					weekCount: config.weekCount,
					horizonSlider: config.horizonSlider,
					horizonBreakoutField: config.horizonBreakoutField,
					breakout: config.breakout,
					distances: config.distances,
					compressedView: config.compressedView,
					fluidMonths: config.fluidMonths,
					weekends: config.weekends,
					slotDuration: config.slotDuration,
					scrollTime: config.scrollTime,
					minTime: config.minTime,
					maxTime: config.maxTime,
					snapToMonth: config.snapToMonth,
					viewSettings: config.viewSettings,
					showInTimezone: showInTimezone || null,
					clientTimezone: timezone || null,
					showMeasure: config.showMeasure,
					showMeasureSettings: config.showMeasureSettings,
					showMeasureThreshold: config.showMeasureThreshold,
					measureAggregate: config.measureAggregate,
					measureDecimalPlaces: config.measureDecimalPlaces,
					measureDecimalSymbol: config.measureDecimalSymbol,
					measureField: config.measureField,
					measureNumberFormat: config.measureNumberFormat,
					measureNumberLabelAfter: config.measureNumberLabelAfter,
					measureNumberLabelBefore: config.measureNumberLabelBefore,
					measureThousandsSeparator: config.measureThousandsSeparator,
					measureThreshold: config.measureThreshold,
					measureThresholdFillColor: config.measureThresholdFillColor,
					measureThresholdFillOpacity:
						config.measureThresholdFillOpacity,
					measureThresholdLineColor: config.measureThresholdLineColor,
					measureType: config.measureType,
					multiSelect: cleanMultiselectForShare(
						seedcodeCalendar.get('multiSelect')
					),
					resourceListFilter: config.resourceListFilter,
					showAltView: publicShare
						? config.unscheduledEnabled &&
							altView.type === 'unscheduled' &&
							altView.show
						: altView.show,
					altViewType: publicShare ? 'unscheduled' : altView.type,
					altViewWidth: altView.width,
					unscheduledFilter: config.unscheduledFilter,
					bookmarkFilter: config.bookmarkFilter,
				},
			};

			// Get and assign horizon breakout collapsed state
			if (config.horizonBreakoutField) {
				value.settings[config.horizonBreakoutField + 'Collapsed'] =
					dataStore.getState(
						config.horizonBreakoutField + 'Collapsed'
					);
			}

			updateAllFilterStates(value.filters);
			for (var i = 0; i < schedules.length; i++) {
				shareSchedules[utilities.stringToID(schedules[i].id)] = {
					id: schedules[i].id,
					sourceID: schedules[i].sourceID,
					sourceTypeID: schedules[i].sourceTypeID,
					backgroundColor: schedules[i].backgroundColor
						? schedules[i].backgroundColor
						: null,
					foregroundColor: schedules[i].foregroundColor
						? schedules[i].foregroundColor
						: null,
					textColor: schedules[i].textColor
						? schedules[i].textColor
						: null,
					borderColor: schedules[i].borderColor
						? schedules[i].borderColor
						: null,
					name: schedules[i].name ? schedules[i].name : null,
					status: schedules[i].status ? schedules[i].status : null,
					customFields:
						platform === 'dbkfm'
							? getCustomFields(schedules[i]) || null
							: null,
				};
			}
			value.schedules = shareSchedules;

			//Create event lists and change set (only if public share)
			if (isPublic(shareWith)) {
				createEventSet(
					start,
					end,
					eventSet,
					changeSet,
					eventList,
					processSet
				);
			} else {
				commitShare(true);
			}

			function processSet() {
				schedule = {
					share: value,
				};

				if (platform === 'dbkfm') {
					var queryID = new Date().getTime(); //Used to create a unique id for our query
					var request = utilities.scriptURL(
						'script=Send%20Filters%20To%20Webviewer',
						queryID
					);
					utilities.getFileOnLoad(
						queryID,
						'sc_watcher.txt',
						'sc_filters.txt',
						function (result) {
							if (result) {
								value.filters[platform] = result;
							}
							dayback.editEvent(
								eventSet,
								null,
								null,
								changeSet,
								null,
								commitShare,
								schedule,
								progressCallback
							);
						}
					);
				} else {
					dayback.editEvent(
						eventSet,
						null,
						null,
						changeSet,
						null,
						commitShare,
						schedule,
						progressCallback
					);
				}
			}

			function commitShare(result) {
				var message;
				if (result === false) {
					message = 'There was an error creating the share';
					utilities.showMessage(message, 0, 8000, 'error');
					return;
				}
				value.events = eventList;
				setShare(value.id, value, false, shareSuccess);
			}

			function shareSuccess() {
				if (callback) {
					callback(value);
				}
			}

			function cleanMultiselectForShare(obj) {
				if (!obj) {
					return null;
				}

				Object.keys(obj).forEach(function (key) {
					if (typeof obj[key].element !== 'undefined') {
						delete obj[key].element;
					}
					if (typeof obj[key].event !== 'undefined') {
						delete obj[key].event;
					}
					if (typeof obj[key].start !== 'undefined') {
						obj[key].start = JSON.stringify(obj[key].start);
					}
				});
				return obj;
			}
		}

		function updateAllFilterStates(filterObj) {
			filterObj.text = getEnabledFilters('textFilters');
			filterObj.resources = getEnabledFilters('resources');
			filterObj.statuses = getEnabledFilters('statuses');
			filterObj.contacts = getEnabledFilters('contacts');
			filterObj.projects = getEnabledFilters('projects');
		}

		function getEnabledFilters(filterName) {
			var output = {};
			var folderHash = {};
			var hasSelected;
			var filters = seedcodeCalendar.get(filterName);
			var i;

			if (filterName === 'textFilters') {
				if (filters === undefined) {
					return null;
				} else {
					return filters;
				}
			}
			if (!filters || !filters.length) {
				return null;
			}

			// Check to see if any are selected. If so we can use those. If not we will need to add all filters
			for (i = 0; i < filters.length; i++) {
				if (filters[i].status && filters[i].status.selected) {
					hasSelected = true;
				}

				//Save the WYSIWYG sort order for the share
				filters[i].visibleSort = i;
			}

			// Add enabled filters
			for (i = 0; i < filters.length; i++) {
				if (!hasSelected || filters[i]?.status?.selected) {
					output[filters[i].id] = cleanFilterItem(filters[i]);
					if (filters[i].folderID) {
						folderHash[filters[i].folderID] = true;
					}
				}
			}

			// Make sure partially selected folders are included
			for (i = 0; i < filters.length; i++) {
				if (
					filters[i].folderID &&
					filters[i].isFolder &&
					folderHash[filters[i].folderID] &&
					!output[filters[i].id]
				) {
					output[filters[i].id] = cleanFilterItem(filters[i]);
				}
			}

			return Object.keys(output).length > 0 ? output : null;
		}

		function cleanFilterItem(filterItem) {
			var output = {};

			output.id = filterItem.id ? filterItem.id : null;
			output.name = filterItem.name ? filterItem.name : null;
			output.nameSafe = filterItem.nameSafe ? filterItem.nameSafe : null;
			output.color = filterItem.color ? filterItem.color : null;
			output.status = filterItem.status ? filterItem.status : null;
			output.isFolder = filterItem.isFolder ? filterItem.isFolder : null;
			output.folderID = filterItem.folderID ? filterItem.folderID : null;
			output.folderName = filterItem.folderName
				? filterItem.folderName
				: null;
			output.description = filterItem.description
				? filterItem.description
				: null;
			output.tags = filterItem.tags
				? utilities.cloneArrayObjects(filterItem.tags)
				: null;
			output.class = filterItem.class ? filterItem.class : null;
			output.capacity = filterItem.capacity ? filterItem.capacity : null;
			output.location = filterItem.location ? filterItem.location : null;
			output.color = filterItem.color ? filterItem.color : null;

			// Add sort priority in order of visible sort which is the natural sort order used when creating the share
			// otherwise use a set sort value that may exist when updating the share or null
			output.sort =
				filterItem.visibleSort !== undefined
					? filterItem.visibleSort
					: (filterItem.sort ?? null);

			return output;
		}

		function getCustomFields(schedule) {
			if (!schedule.customFields) {
				return;
			}

			return JSON.parse(JSON.stringify(schedule.customFields));
		}

		function getShareURL(id, isPublic, embed) {
			return (
				(embed ? '<iframe src="' : '') +
				utilities.getBaseURL(true, true) +
				(isPublic ? 'shared/' : '#/?bookmarkID=') +
				id +
				(embed ? '"></iframe>' : '')
			);
		}

		function remove(shareID, callback) {
			//Get list of events associated with this share
			daybackIO.getShareData(shareID + '/events', getEventData, null);
			function getEventData(events) {
				//If no events are in the share
				if (!events) {
					processDelete(shareID, [], callback);
					return;
				}
				var eventList = Object.keys(events);
				deleteSharedEvents(shareID, eventList, true, callback);
			}
		}

		function deleteSharedEvents(shareID, eventList, removeShare, callback) {
			daybackIO.getEventData(
				'shares',
				null,
				null,
				eventList,
				function (eventData) {
					if (removeShare) {
						processDelete(shareID, eventData, callback);
					} else {
						updateSharedEvents(shareID, eventData, callback);
					}
				},
				//Mutate result so we wrap the shares in the appropriate eventID
				function (data, eventID) {
					var result = {};
					result[eventID] = data;
					return result;
				}
			);
		}

		function processDelete(shareID, eventData, callback) {
			daybackIO.setShareData(
				null,
				shareID,
				null,
				false,
				function (result) {
					updateSharedEvents(shareID, eventData, callback);
				},
				null,
				null,
				null
			);
		}

		function updateSharedEvents(shareID, sharedEvents, callback) {
			var sharedEvent;
			var eventID;
			var shares;

			//Run callback
			if (callback) {
				callback();
			}

			//Update or remove event if no more shares are attached to that event
			for (var i = 0; i < sharedEvents.length; i++) {
				sharedEvent = sharedEvents[i];
				eventID = Object.keys(sharedEvent)[0];
				shares = sharedEvent[eventID];
				if (shares[shareID]) {
					delete shares[shareID];
				}

				if (Object.keys(shares).length) {
					//Event is still valid
					daybackIO.setEventData(
						eventID + '/shares',
						shareID,
						null,
						false,
						null,
						null
					);
				} else {
					//Event needs to be deleted
					daybackIO.setEventData(
						null,
						eventID,
						null,
						false,
						null,
						null
					);
				}
			}
		}

		function setShare(shareID, value, update, callback) {
			daybackIO.setShareData(
				null,
				shareID,
				value,
				true,
				callback,
				null,
				createGroupData(value),
				isUserOnlyShare(value)
			);
		}

		function createEventSet(
			start,
			end,
			eventSet,
			changeSet,
			eventList,
			callback
		) {
			//eventSet and changeSet need to be arrays
			getEvents(start, end, processResult);
			var fieldMap;
			var changes;
			var event;

			function processResult(events) {
				//Create event lists and change set
				for (var i = 0; i < events.length; i++) {
					fieldMap = events[i].schedule.fieldMap;
					changes = {};
					event = events[i];
					//Check if event is in the current view range
					if (
						event.end.isBefore(start, 'day') ||
						event.start.isAfter(end, 'day') ||
						!manageFilters.isEventShown(event)
					) {
						continue;
					}
					eventList[
						utilities.generateEventID(
							event.schedule.sourceTypeID,
							event.schedule.id,
							event.eventID
						)
					] = {
						id: event.eventID,
					};
					for (var property in fieldMap) {
						changes[property] = event[property];
					}

					changes.shareSourceID = event.schedule.sourceID;
					changes.shareScheduleID = event.schedule.id;
					changes.shareSourceTypeID = event.schedule.sourceTypeID;

					// Add labelMapOverride
					if (event.labelMapOverride) {
						changes.labelMapOverride = JSON.parse(
							JSON.stringify(event.labelMapOverride)
						);
					}

					eventSet.push(event);
					changeSet.push(changes);
				}
				if (callback) {
					callback();
				}
			}
		}

		function getBookmark(bookmarkID, callback) {
			var bookmark;
			var userShareList;
			var callbackCount = 0;
			if (bookmarkID) {
				daybackIO.getShareData(
					bookmarkID,
					function (result) {
						bookmark = result;
						verifyUserBookmark();
					},
					null
				);
				getUserShareList(function (result) {
					userShareList = result;
					verifyUserBookmark();
				});
			} else {
				callback(null);
			}

			function verifyUserBookmark() {
				callbackCount++;
				if (callbackCount >= 2) {
					callback(
						bookmark && userShareList[bookmark.id] ? bookmark : null
					);
				}
			}
		}

		function update(shareID, callback, progressCallback, viewOnly) {
			var config = seedcodeCalendar.get('config');
			var schedules = seedcodeCalendar.get('schedules');
			var sidebar = seedcodeCalendar.get('sidebar');
			let eventFetchCount = 0;

			// Clear breakout as it could interfere with switching views
			config.breakout = null;

			daybackIO.getShareData(shareID, processShareData, null);
			function processShareData(share) {
				var schedule = {share: share};
				var shareSchedules = share.schedules;
				var shareSchedule;
				var missingSchedules = [];
				var missingSources = [];
				var missingSource;
				var deauthedSources = [];
				var eventSet = [];
				var changeSet = [];
				var eventList = {};
				var cleanedFilters = {};
				var error = {};
				var errorTranslations;
				var i;

				//Verify all schedules in bookmark are available before continuing
				Object.keys(shareSchedules).forEach(function (key) {
					if (shareSchedules[key].status.selected) {
						var matchingSchedule = schedules.filter(
							function (curSchedule) {
								return (
									utilities.stringToID(curSchedule.id) === key
								);
							}
						);
						if (
							matchingSchedule.length <= 0 &&
							shareSchedules[key].sourceTypeID === 5
						) {
							// If there were no matches based on ID and this is a microsoft 365 sourceTypeID
							// Then the ID's may be different for another user so attempt to match on name

							matchingSchedule = schedules.filter(
								function (curSchedule) {
									return (
										curSchedule.name ===
											shareSchedules[key].name &&
										curSchedule.sourceTypeID ===
											shareSchedules[key].sourceTypeID
									);
								}
							);
						}
						if (matchingSchedule.length <= 0) {
							missingSchedules.push(shareSchedules[key].name);
							missingSource = manageEventSources.getTemplate(
								shareSchedules[key].sourceTypeID
							);
							if (
								missingSources.indexOf(missingSource.name) < 0
							) {
								missingSources.push(missingSource.name);
								if (!missingSource.status.authed) {
									deauthedSources.push(missingSource.name);
								}
							}
						}
					}
				});

				//If there are any missing schedules, show a modal and prevent update from continuing
				if (missingSchedules.length > 0) {
					errorTranslations = $translate.instant([
						'correct',
						'Visit',
						'Update',
					]);
					errorTranslations = $translate.instant(
						[
							"Calendar Can't be found",
							'Bookmark calendar not found message',
						],
						{
							calendarSchedules: utilities.humanJoin(
								missingSchedules,
								'Calendar',
								'"',
								true,
								true
							),
							calendarSources: utilities.humanJoin(
								deauthedSources.length > 0
									? deauthedSources
									: missingSources,
								'Account',
								null,
								true,
								true
							),
							action: errorTranslations[
								viewOnly ? 'Visit' : 'Update'
							].toLowerCase(),
						}
					);

					error.title = errorTranslations["Calendar Can't be found"];
					error.content =
						errorTranslations[
							'Bookmark calendar not found message'
						];
					error.cancelButtonText = 'ok';
					if (callback) {
						callback(null, error);
					}
					return error;
				}

				if (config.showMeasure?.status) {
					manageMeasure.hideMeasure();
				}

				//Disable all enabled schedules first so the following loads quickly
				for (i = 0; i < schedules.length; i++) {
					if (schedules[i].status.selected === true) {
						manageSchedules.selectSchedule(schedules[i]);
					}
				}

				// Track when we are done rendering so we can report that properly
				var eventsRenderedCount = $rootScope.$on(
					'eventsRendered',
					() => {
						const loadingEvents =
							seedcodeCalendar.get('loading-events');
						eventFetchCount--;
						if (eventFetchCount <= 0 && !loadingEvents) {
							eventsRenderedCount();
							setTimeout(() => {
								calendarEvents.onEventAfterAllRender(
									null,
									null,
									true,
									shareID
								);
							}, 0);
						}
					}
				);

				//Reset all filters so we can start clean
				manageFilters.resetFilterState();

				//Update current config to match share
				manageSettings.applyShareSettings(config, share);
				config.horizonSlider = manageSettings.cleanHorizonSlider(
					config.horizonSlider,
					null,
					share
				);

				// Update resource config options to make sure they aren't out of sync when we re-render the view
				// config.resourceColumns = manageResources.getResourceColumns();
				//config.resources = manageResources.getViewed();
				// config.resourcesAll = manageResources.getFiltered;

				// manageResources.init(config.resourceColumns);

				// Apply resource list filter without updating the view as that will be done later
				manageFilters.applyFilterSearch(
					config.resourceListFilter,
					'resource',
					false,
					true,
					null
				);

				// Restore selected filters
				if (share.filters) {
					for (var property in share.filters) {
						if (
							!manageFilters.restoreFilters(
								property,
								null,
								null,
								share
							)
						) {
							errorTranslations = $translate.instant([
								'Error',
								'Bookmark update filter error message',
								'Bookmark visit filter error message',
							]);
							error.title = errorTranslations['Error'];
							error.content = viewOnly
								? errorTranslations[
										'Bookmark visit filter error message'
									]
								: errorTranslations[
										'Bookmark update filter error message'
									];
							error.cancelButtonText = 'ok';
							if (callback) {
								callback(null, error);
							}
							return error;
						}

						// Filters need to be cleaned for updating in firebase as the restore filters above has most likely mutated them
						if (property === 'text') {
							cleanedFilters[property] = share.filters[property];
						} else {
							cleanedFilters[property] = {};
							for (var filterID in share.filters[property]) {
								cleanedFilters[property][filterID] =
									cleanFilterItem(
										share.filters[property][filterID]
									);
							}
						}
					}

					share.filters = cleanedFilters;
				}

				// Restore Resources
				eventFetchCount++;
				manageResources.init(config.resourceColumns);

				// Register a watcher for when the calendar has rendered
				var eventsRendered = $rootScope.$on(
					'eventsRendered',
					function () {
						//Stop watching for events to render from enabling all of the share schedules
						eventsRendered();

						var calendarRendered = $rootScope.$on(
							'eventsRendered',
							function () {
								const loadingEvents =
									seedcodeCalendar.get('loading-events');

								//All share filters and calendars have loaded, now update the share
								if (!loadingEvents) {
									//Stop watching for events to render from initializing the calendar
									calendarRendered();
									// Call afterevents rendered passing in that it was from a bookmark call
									if (!viewOnly) {
										updateShare();
									}
								}
							}
						);

						restoreEvents();

						// Restore analytics state (must run after schedules have been restored)
						if (share.settings.showMeasure) {
							if (
								config.showMeasure &&
								config.showMeasure.status
							) {
								manageMeasure.showMeasure();
							} else {
								manageMeasure.hideMeasure();
							}
						}
					}
				);

				// Take this out of the call stack so the previously disabled calendars are fully removed before procceeding. Otherwise we may get double events
				window.setTimeout(function () {
					// Initialize the calendar view to redraw with new settings
					eventFetchCount++;
					seedcodeCalendar.init('initCalendar', null);
					$rootScope.$broadcast('reload-unscheduled', true);
				}, 0);

				function restoreEvents() {
					let fetchCountIncreased = false;
					//Enable the calendars for the share. This is being done before initializing the calendar as if it is done after it was causing events to double up when nagivating
					for (i = 0; i < schedules.length; i++) {
						shareSchedule =
							shareSchedules[
								utilities.stringToID(schedules[i].id)
							];
						if (
							shareSchedule &&
							shareSchedule.status.selected !==
								schedules[i].status.selected
						) {
							if (!fetchCountIncreased) {
								eventFetchCount++;
								fetchCountIncreased = true;
							}
							manageSchedules.selectSchedule(schedules[i], false);
						}
					}
				}

				function updateShare() {
					var shareEvents = share.events;
					var deletedEvents = [];

					//Create our event set and assign eventList (Get all viewed events)
					createEventSet(
						moment(share.start),
						moment(share.end),
						eventSet,
						changeSet,
						eventList,
						processSet
					);

					function processSet() {
						dayback.editEvent(
							eventSet,
							null,
							null,
							changeSet,
							null,
							commitShare,
							schedule,
							progressCallback
						);

						for (var property in share.events) {
							if (!eventList[property]) {
								deletedEvents.push(property);
							}
						}

						if (deletedEvents.length) {
							deleteSharedEvents(
								share.id,
								deletedEvents,
								false,
								null
							);
						}
					}
				}

				function commitShare(result) {
					var message;
					if (result === false) {
						message = 'There was an error updating the share';
						utilities.showMessage(message, 0, 8000, 'error');
						return;
					}

					//If this share is in DBKFM update the filters as we store those in the share
					if (share.platform === 'dbkfm') {
						updateAllFilterStates(share.filters);
					}

					share.events = eventList;
					setShare(share.id, share, false, shareSuccess);
				}

				function shareSuccess() {
					if (callback) {
						callback(share);
					}
				}
			}
		}

		function getEvents(start, end, callback) {
			var schedules = seedcodeCalendar.get('schedules');
			var config = seedcodeCalendar.get('config');
			var schedulesQueried = 0;
			var result = [];
			var finalResult = [];
			var selectedSchedules = [];
			for (var i = 0; i < schedules.length; i++) {
				if (schedules[i].status && schedules[i].status.selected) {
					selectedSchedules.push(schedules[i]);
					schedules[i].source.events(
						start,
						end,
						config.timezone,
						gatherEvents
					);
				}
			}

			function gatherEvents(data) {
				schedulesQueried++;
				if (data) {
					result = result.concat(data);
				}
				if (schedulesQueried === selectedSchedules.length) {
					//all callbacks done
					for (var i = 0; i < result.length; i++) {
						if (manageFilters.isEventShown(result[i])) {
							result[i].start = moment(result[i].start);
							result[i]._start = result[i].start;
							result[i].end = moment(result[i].end);
							if (result[i].allDay) {
								//Customized for SeedCode. We want to display all day events as inclusive rather than exclusive end day
								//this logic is also being used in fullcalendar/event-manager.js and should be consolidated.
								result[i]._allDay = result[i].allDay;
								if (result[i].end) {
									result[i].end.add(1, 'days');
								}
							}
							result[i]._end = result[i].end;
							result[i]._id = result[i].eventID;
							result[i].source = result[i].schedule.source;
							finalResult.push(result[i]);
						}
					}
					if (callback) {
						callback(finalResult);
					}
				}
			}
		}

		function getUserShareList(callback, excludeExpired) {
			const now = moment().valueOf();
			let callbackCount = 0;
			let shareList = {};
			firebaseIO.getGroupData('shares', processResult, null);
			firebaseIO.getUserData(null, 'shares', processResult, null);

			function processResult(result) {
				callbackCount++;
				for (let property in result) {
					let expired = result[property].expires
						? result[property].expires < now
						: false;
					if (excludeExpired && expired) {
						continue;
					}
					shareList[property] = result[property];
				}

				//Verify that we've finished setting group and user data
				if (callbackCount >= 2) {
					callback(shareList);
				}
			}
		}

		function isUserOnlyShare(share) {
			return share.shareWith === 'self';
		}

		function isPublic(shareWith) {
			return shareWith === 'public';
		}
	}

	function isPublic(shareWith) {
		return (
			typeof shareWith === 'undefined' ||
			shareWith === null ||
			shareWith === 'public'
		);
	}
})();
